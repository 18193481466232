import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";
// import "./category-form.css";

function CategoryForm({ show, handleClose, handleSubmit, category }) {
  const [formData, setFormData] = useState({
    category_name: category?.category_name || "",
    text_color: category?.text_color || "",
    is_home_listed: category?.is_home_listed || false,
    icon: null,
    category_banner: null,
  });

  useEffect(() => {
    if (category) {
      setFormData({
        category_name: category.category_name,
        text_color: category.text_color,
        is_home_listed: category.is_home_listed,
        icon: null,
        category_banner: null,
      });
    }
  }, [category]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <Modal contentClassName="modal-height" size="md" className="popup-model" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{category ? "Edit Category" : "Add Category"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-category-div">
        <Form onSubmit={handleFormSubmit}>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formCategoryName">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  name="category_name"
                  value={formData.category_name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formTextColor">
                <Form.Label>Text Color</Form.Label>
                <Form.Control
                  type="color"
                  name="text_color"
                  value={formData.text_color}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group controlId="formIsHomeListed">
                <Form.Check
                  type="checkbox"
                  label="Is Home Listed"
                  name="is_home_listed"
                  checked={formData.is_home_listed}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formIcon">
                <Form.Label>Category Icon</Form.Label>
                <Form.Control
                  type="file"
                  name="icon"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formBanner">
                <Form.Label>Category Banner</Form.Label>
                <Form.Control
                  type="file"
                  name="category_banner"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button
            variant="primary"
            type="submit"
            className="w-100 update-add-btn"
          >
            {category ? "Update" : "Add"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CategoryForm;
