import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./business.css";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { MdEdit, MdDelete } from "react-icons/md";
import BusinessForm from "./business_form";
import Popup from "reactjs-popup";
import { Snackbar, Alert } from "@mui/material";

const BusinessComponent = ({ onPageChange }) => {
  const [businessList, setBusinessList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedBusinessList, setSelectedBusinessList] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedBusinessToDelete, setSelectedBusinessToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const handleDeleteClick = (business) => {
    setSelectedBusinessToDelete(business);
    setShowDeletePopup(true);
  };

  const confirmDelete = () => {
    const url = `${baseUrl}superadmin/business/delete/${selectedBusinessToDelete.id}/`;
    axios
      .delete(url, config)
      .then((response) => {
        fetchBusinessList();
        setShowDeletePopup(false);
      })
      .catch((error) => {
        console.error("Error deleting business:", error);
      });
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const fetchBusinessList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/business/`, config)
      .then((response) => {
        setLoading(false);

        setBusinessList(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Business Error:", error);
        setLoading(false);
      });
  };

  const fetchCategory = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/category/`, config)
      .then((response) => {
        const category = response.data.category.map((cat) => ({
          id: cat.id,
          name: cat.category_name,
        }));

        setCategoryList(category);
        // console.log(category);
      })
      .catch((error) => {
        console.error("Business Details Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    fetchBusinessList();
    fetchCategory();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "logo",
      headerName: "Business Logo",
      width: 150,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          <img src={params.value} alt="Business Logo" width="60" height="60" />
        </a>
      ),
    },
    {
      field: "business_title",
      headerName: "Business Name",
      width: 150,
      editable: true,
    },
    {
      field: "mobile_number",
      headerName: "Phone Number",
      width: 150,
      editable: true,
    },
    {
      field: "category_id",
      headerName: "Category",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: categoryList.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })),
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      editable: true,
    },
    {
      field: "service_range_km",
      headerName: "Service Range",
      width: 150,
      editable: true,
    },
    {
      field: "service_localities",
      headerName: "Service Localities",
      width: 150,
      editable: true
    },
    {
      field: "place",
      headerName: "Place",
      width: 150,
      editable: true,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      width: 150,
      editable: true,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      width: 150,
      editable: true,
    },
    {
      field: "banner",
      headerName: "Banner",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          <img
            src={params.value}
            alt="Business Banner"
            width="60"
            height="60"
          />
        </a>
      ),
    },
    {
      field: "thumb",
      headerName: "Thumb",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          <img src={params.value} alt="Business Thumb" width="60" height="60" />
        </a>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div className="edit-delete-business">
          <div className="edit">
            <MdEdit
              variant="primary"
              className="ml-2"
              onClick={(e) => {
                e.stopPropagation();
                handleShowModal(params.row);
              }}
            />
          </div>
          <div className="delete">
            <MdDelete
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(params.row);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleShowModal = (business = null) => {
    setSelectedBusinessList(business);
    setErrorMessage(""); 
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBusinessList(null);
    setErrorMessage(""); 
  };

  const handleFormSubmit = (formData) => {
    const url = selectedBusinessList
      ? `${baseUrl}superadmin/business/edit/${selectedBusinessList.id}/`
      : `${baseUrl}app/create-user-and-business/`;
    const method = selectedBusinessList ? "put" : "post";

    const form = new FormData();
    form.append("business_title", formData.business_title);
    form.append("mobile_number", formData.mobile_number);
    form.append("category_id", formData.category_id);
    form.append("description", formData.description);
    form.append("service_range_km", formData.service_range_km);
    form.append("service_localities", formData.service_localities);
    form.append("business_place", formData.business_place);
    form.append("latitude", formData.latitude);
    form.append("longitude", formData.longitude);
    if (formData.logo) form.append("logo", formData.logo);
    if (formData.thumb) form.append("thumb", formData.thumb);
    if (formData.banner) form.append("banner", formData.banner);
    form.append("name", formData.name);
    // form.append("password", formData.password);
    form.append("user_place", formData.user_place);
    form.append("phone", formData.phone);

    axios({
      method,
      url,
      data: form,
      headers: config.headers,
    })
      .then((response) => {
        fetchBusinessList();
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.owner?.[0] || "An error occurred.");
        } else {
          setErrorMessage("An unexpected error occurred.");
          
        }
        setSnackbarOpen(true);
      });
  };

  const handleBusinessClick = (params) => {
    const business_Id = params.row.id;
    onPageChange("sub_business", business_Id);
  };

  return (
    <>
      <Box sx={{ height: 400, width: "80%" }} className="main-div">
        <button className="btn-add" onClick={() => handleShowModal()}>
          + Add Business
        </button>
        {loading ? (
          <CircularProgress className="spinner-loader"/>
        ) : (
          <>
            <DataGrid
              className="business-list"
              rows={businessList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              pageSizeOptions={[100]}
              disableRowSelectionOnClick
              onRowClick={handleBusinessClick}
            />
            <BusinessForm
              show={showModal}
              handleClose={handleCloseModal}
              handleSubmit={handleFormSubmit}
              business={selectedBusinessList}
              categoryList={categoryList}
            />
             
          </>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {errorMessage}
        </Alert>

      </Snackbar>
      <Popup
        className="popup-container"
        contentStyle={{
          borderRadius: "20px",
          backgroundColor: "white",
        }}
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        modal
        nested
      >
        {(close) => (
          <div className="delete-img-div">
            <h3 className="delete-img-heading">Are you sure?</h3>
            <p className="delete-img-p">Do you really want to delete?</p>
            <div className="delete-btn-div">
              <button
                onClick={() => {
                  confirmDelete();
                  close();
                }}
              >
                Delete
              </button>
              <button onClick={close}>Cancel</button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default BusinessComponent;
