import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { MdExpandMore, MdEdit, MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import "./blog-component.css";

const BlogTable = ({ blogs, onEdit, onDelete }) => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleDeleteClick = (blog) => {
    setSelectedBlog(blog);
  };

  const confirmDelete = () => {
    if (selectedBlog) {
      onDelete(selectedBlog.id);
      setSelectedBlog(null);
      console.log(selectedBlog.id);
    }
  };
  const [expandedBlogs, setExpandedBlogs] = useState({});

  const handleExpandClick = (id) => {
    setExpandedBlogs((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      {blogs.length === 0 ? (
        <Typography variant="h6" align="center">
          No blogs available
        </Typography>
      ) : (
        blogs.map((blog) => (
          <Card key={blog.id} sx={{ maxWidth: 745, marginBottom: 2 }}>
            <CardHeader
              avatar={
                <Avatar src={blog.blog_author_image} alt={blog.blog_author} />
              }
              title={blog.blog_author}
              subheader={new Date(blog.blog_date).toLocaleDateString()}
              sx={{ textAlign: "left" }}
            />
            <CardMedia
              component="img"
              height="194"
              image={blog.blog_image}
              alt={blog.blog_author}
            />
            <CardActions
              disableSpacing
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h5" className="p-2" sx={{ flexGrow: 1 }}>
                  {blog.blog_title}
                </Typography>
                <IconButton
                  onClick={() => handleExpandClick(blog.id)}
                  aria-label="expand"
                  aria-expanded={!!expandedBlogs[blog.id]}
                >
                  <MdExpandMore
                    style={{
                      transform: expandedBlogs[blog.id]
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s",
                    }}
                  />
                </IconButton>
              </Box>
            </CardActions>
            <Collapse
              in={!!expandedBlogs[blog.id]}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {blog.blog_body}
                </Typography>
                <div className="edit-delete-blog">
                  <div className="edit">
                    <MdEdit
                      variant="primary"
                      className="ml-2"
                      onClick={() => onEdit(blog)}
                    />
                  </div>
                  <div className="delete">
                    <MdDelete onClick={() => handleDeleteClick(blog)} />
                  </div>
                </div>
              </CardContent>
            </Collapse>
          </Card>
        ))
      )}
      {selectedBlog && (
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={true}
          onClose={() => setSelectedBlog(null)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button
                  onClick={() => {
                    confirmDelete();
                    close();
                  }}
                >
                  Delete
                </button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};

export default BlogTable;
