import React, { useState, useEffect } from 'react';
import './dashboard.css';
import Card from 'react-bootstrap/Card';
import axios from '../../../../network/api';
import { baseUrl } from '../../../../network/constants/constants';
import config from '../../../../network/auth';
import { Container, Spinner } from 'react-bootstrap';

const Dashboard = () => {
  const [businessStatus, setBusinessStatus] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBusinessStatus = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}superadmin/business-status/`, config);
        setBusinessStatus(response.data);
      } catch (error) {
        console.error('fetchFeedback Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessStatus();
  }, []);

  const cardData = [
    { title: businessStatus.total_no_of_business, text: 'Total Businesses' },
    { title: businessStatus.total_no_of_users, text: 'Total Users' },
    { title: businessStatus.total_no_of_business_users, text: 'Total Business Users' },
    { title: businessStatus.total_no_of_subscription, text: 'Total Subscriptions' },
    { title: businessStatus.total_no_of_Actived_subscription, text: 'Active Subscriptions' },
    { title: businessStatus.total_no_of_Renewaled_subscription, text: 'Renewed Subscriptions' },
    { title: businessStatus.total_no_of_Inactived_subscription, text: 'Inactive Subscriptions' },
    { title: businessStatus.total_no_of_cancelled_subscription, text: 'Cancelled Subscriptions' },
    { title: businessStatus.total_no_of_orders, text: 'Total Orders' },
    { title: businessStatus.total_no_of_paided_orders, text: 'Paid Orders' },
    { title: businessStatus.total_no_of_unpaided_orders, text: 'Unpaid Orders' },
  ];

  return (
    <Container fluid className='center'>
    <div className="dashboard-container">
      {loading ? (
          <div className="loader-container">
        <Spinner className='spinner-loader' />
        </div>
      ) : (
        cardData.map((card, index) => (
          <Card key={index} style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>{card.title}</Card.Title>
              <Card.Text>{card.text}</Card.Text>
            </Card.Body>
          </Card>
        ))
      )}
    </div>
    </Container>
  );
};

export default Dashboard;
