import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DataGrid } from "@mui/x-data-grid";
import GalleryComponent from "../gallery/gallery";
import BusinessFaq from "../business_faq/business_faq";
import Unsubscribe from "../unsubscribe/unsubscribe";
import Box from "@mui/material/Box";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import MainScreen from "../plan_subplan_main/plan_subplan_main";
import { Snackbar, Alert } from "@mui/material";
import "./tab_menu.css";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
const TabMenu = ({ business_id }) => {
  const [value, setValue] = React.useState(0);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const fetchSubscriptionDetails = () => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}superadmin/subscription-by-business-id/${business_id}/`,
        config
      )
      .then((response) => {
        setLoading(false);
        setSubscriptionDetails(response.data.subscriptions);
        // console.log(response.data.subscriptions);
      })
      .catch((error) => {
        console.error("Business Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (business_id) {
      fetchSubscriptionDetails();
    }
  }, [business_id]);

  const handleFilterSubscriptions = () => {
    if (!subscriptionDetails || subscriptionDetails.length === 0) {
      setSnackbarMessage("No subscriptions to filter.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }
  
    if (!startDate || !endDate) {
      setSnackbarMessage("Please select both start and end dates.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
  
    const filtered = subscriptionDetails.filter((subscription) => {
      const orderDate = new Date(subscription.order_paid_date);
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      
      orderDate.setHours(0, 0, 0, 0);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999); 
  
      return orderDate >= start && orderDate <= end;
    });
    if (filtered.length > 0) {
    setFilteredSubscriptions(filtered);
    setIsFiltered(true);
    setSnackbarMessage("Subscriptions filtered successfully.");
    setSnackbarSeverity("success");

   } else {
      setSnackbarMessage("No subscriptions found in the selected range.");
      setSnackbarSeverity("info");
    }
    setSnackbarOpen(true);
  };
  

  const handleReset = () => {
    setFilteredSubscriptions(subscriptionDetails); 
    setStartDate("");
    setEndDate("");
    setIsFiltered(false);
    setSnackbarMessage("Filter reset.");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "order_paid_date",
      headerName: "Order paid date",
      width: 200,
    },
    {
      field: "subscription_amount",
      headerName: "Subscription amount",
      width: 200,
    },
    {
      field: "order_payment_id",
      headerName: "Order payment id",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "visits_left",
      headerName: "Visits left",
      width: 100,
    },
    {
      field: "subscription_product_name",
      headerName: "Subscription product name",
      width: 200,
    },
    {
      field: "customer_name",
      headerName: "Customer name",
      width: 150,
    },
  ];
  const exportToExcel = () => {
    const dataToExport = isFiltered ? filteredSubscriptions : subscriptionDetails;
  
    if (!dataToExport || dataToExport.length === 0) {
      setSnackbarMessage("No data to export.");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      return;
    }
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "Subscriptions.xlsx");
  
    setSnackbarMessage("Excel sheet downloaded successfully.");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };
  

  return (
    <Container fluid>
      <Card>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "orangered",
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Subscription" className="menu-items" />
          <Tab label="Plans" className="menu-items" />
          <Tab label="Unsubscribe" className="menu-items" />
          <Tab label="Gallery" className="menu-items" />
          <Tab label="Business Faq" className="menu-items" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box className="box-container mt-4">
            <div className="download-excel">
              <div className="date-input-container">
                <input
                  className="date-input"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                />
                <input
                  className="date-input"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  placeholder="End Date"
                  min={startDate}
                />
                  {/* {!isFiltered ? ( */}
                <button
                  className="filter-btn"
                  onClick={handleFilterSubscriptions}
                >
                  Apply Range
                </button>
                    {/* ) : (  */}
                <button className="filter-btn" onClick={handleReset}>
                  Reset
                </button>
                  {/* )} */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={exportToExcel}
                style={{ marginBottom: "10px", textTransform: "none" }}
              >
                <DownloadIcon /> Download
              </Button>
            </div>
            <DataGrid
              pageSize={20}
              autoHeight
              getRowId={(row) => row.id}
              rows={
                filteredSubscriptions.length
                  ? filteredSubscriptions
                  : subscriptionDetails
              }
              columns={columns}
              editMode="row"
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MainScreen business_id={business_id}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Unsubscribe business_id={business_id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div>
            <GalleryComponent business_id={business_id} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BusinessFaq business_id={business_id} />
        </TabPanel>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TabMenu;
