import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Popup from "reactjs-popup";
import "./category-component.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { baseUrl } from "../../../../../network/constants/constants";

const CategoryTable = ({ categories, onEdit, onDelete }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleDeleteClick = (category) => {
    setSelectedCategory(category);
  };

  const confirmDelete = () => {
    if (selectedCategory) {
      onDelete(selectedCategory.id);
      setSelectedCategory(null);
      console.log(selectedCategory.id);
    }
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Icon</th>
            <th>Category Name</th>
            <th>Text Color</th>
            <th>Is Home Listed</th>
            <th>Banner</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {categories.length === 0 ? (
                        <tr>
                            <td colSpan={4} className="text-center">No categories available</td>
                        </tr>
                    ) : (categories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>
                <img
                  src={`${baseUrl}${category.icon}`}
                  alt={category.category_name}
                  width={30}
                />
              </td>
              <td>{category.category_name}</td>
              <td style={{ color: category.text_color }}>
                {category.text_color}
              </td>
              <td>{category.is_home_listed ? "Yes" : "No"}</td>
              <td>
                <img
                  src={`${baseUrl}${category.category_banner}`}
                  alt={`${category.category_name} banner`}
                  width={50}
                />
                {/* Display banner */}
              </td>
              <td>
                <div className="edit-delete-category">
                  <div className="edit">
                    <MdEdit
                      variant="primary"
                      className="ml-2"
                      onClick={() => onEdit(category)}
                    />
                  </div>
                  <div className="delete">
                    <MdDelete onClick={() => handleDeleteClick(category)} />
                  </div>
                </div>
              </td>
            </tr>
          )))}
        </tbody>
      </Table>
      {selectedCategory && (
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={true}
          onClose={() => setSelectedCategory(null)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button
                  onClick={() => {
                    confirmDelete();
                    close();
                  }}
                >
                  Delete
                </button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};
export default CategoryTable;
