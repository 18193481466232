import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { baseUrl } from '../../../../../network/constants/constants';
import './request_dialog.css';

const RequestDialog = ({ modalOpen, handleCloseModal, selectedRequest }) => {
  if (!selectedRequest || selectedRequest.length === 0) return null; 
  const selectedRequestObject = selectedRequest?.[0]; 

  if (!selectedRequestObject) return null; 

  const { old: oldValues = {}, edits: editedValues = {}, status } = selectedRequestObject;

  const renderImage = (imgPath, isEdited) => {
    if (imgPath) {
      if (isEdited) {
      
        const imageData = `data:image/jpeg;base64,${imgPath}`;
        return <img src={imageData} alt="images" style={{ width: '90px', height: '90px' }} />;
      } else {
       
        return <img src={`${baseUrl}/${imgPath}`} alt="images" style={{ width: '90px', height: '90px' }} />;
      }
    } else {
      return 'No edits';
    }
  };
  

  const renderRow = (field, oldValue, newValue) => {
    const isImageField = ['thumb', 'banner', 'logo'].includes(field.toLowerCase());
  
    return (
      <TableRow key={field}>
        <TableCell>{field}</TableCell>
        <TableCell>{isImageField ? renderImage(oldValue, false) : oldValue !== undefined ? oldValue : 'No edits'}</TableCell>
        <TableCell>{isImageField ? renderImage(newValue, true) : newValue !== undefined ? newValue : 'No edits'}</TableCell>
      </TableRow>
    );
  };
  

  return (
    <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
      <DialogTitle className='request-head'>Request Details</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="h6">Status: <span className='status-span'>{status}</span></Typography> 
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='request-subhead'>Field</TableCell>
                <TableCell className='request-subhead'>Old Value</TableCell>
                <TableCell className='request-subhead'>Edited Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
        
              {renderRow('Business Title', oldValues.business_title, editedValues.business_title)}
              {renderRow('Description', oldValues.description, editedValues.description)}
              {renderRow('Banner', oldValues.banner, editedValues.banner)}
              {renderRow('Logo', oldValues.logo, editedValues.logo)}
              {renderRow('Thumb', oldValues.thumb, editedValues.thumb)}
              {renderRow('Category Name', oldValues.category_name, editedValues.category_name)}
              {renderRow('Latitude', oldValues.latitude, editedValues.latitude)}
              {renderRow('Longitude', oldValues.longitude, editedValues.longitude)}
              {renderRow('Mobile Number', oldValues.mobile_number, editedValues.mobile_number)}
              {renderRow('Place', oldValues.place, editedValues.place)}
              {renderRow('Service Range (km)', oldValues.service_range_km, editedValues.service_range_km)}
              {renderRow('Service Localities', oldValues.service_localities, editedValues.service_localities)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} className='request-close'>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestDialog;
