import React, { useEffect, useState } from "react";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import CategoryTable from "../category-component/category-component";
import { Button, Spinner } from "react-bootstrap";
import CategoryForm from "../category-component/category-form";
import './category-screen.css';


export const CategoryScreen = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Get categories
  const fetchCategories = () => {
    setLoading(true);
    axios
      .get(baseUrl + `app/category/`, config)
      .then((response) => {
        setCategories(response.data.category);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Categories Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleShowModal = (category = null) => {
    setSelectedCategory(category);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCategory(null);
  };

  const handleFormSubmit = (formData) => {
    const url = selectedCategory
      ? `${baseUrl}app/category/edit-or-delete/${selectedCategory.id}/`
      : `${baseUrl}app/category/create/`;
    const method = selectedCategory ? "put" : "post";

    const form = new FormData();
    form.append("category_name", formData.category_name);
    form.append("text_color", formData.text_color);
    form.append("is_home_listed", formData.is_home_listed);
    if (formData.icon) form.append("icon", formData.icon);
    if (formData.category_banner) form.append("category_banner", formData.category_banner);

    axios({
      method,
      url,
      data: form,
      headers: config.headers,
    })
      .then((response) => {
        fetchCategories();
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error saving category:", error);
      });
  };
  const deleteCategory = (categoryId) => {
    setLoading(true);
  
    axios
      .delete(`${baseUrl}app/category/edit-or-delete/${categoryId}/`, config)
      .then((response) => {
        fetchCategories(); 
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        setLoading(false);
      });
  };
  
  return (
    <div className="category-container">
   
      <button className="btn-add"  onClick={() => handleShowModal()}>+ Add Category</button>
      {loading ? (
          <div className="loader-container">
        <Spinner className='spinner-loader' />
        </div>
      ) : (
      <div>
        <CategoryTable categories={categories} loading={loading} onEdit={handleShowModal} onDelete={deleteCategory} />
      </div>
      )}
      <CategoryForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleFormSubmit}
        category={selectedCategory}
      />
      
    </div>
  );
};

export default CategoryScreen;









