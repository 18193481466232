export const menuList = [
  {
    name: "Dashboard",
    icon: "dashboard",
  },
  
  {
    name: "General FAQ",
    icon: "quiz",
  },
 
  {
    name: "Business",
    icon: "business",
  },
  {
    name: "Category",
    icon: "category",
  },
  {
    name: "Merchant Edit Request",
    icon: "list",
  },
  {
    name: "Feedbacks",
    icon: "feedback",
  },
  {
    name: "Blog",
    icon: "book",
  },
  {
    name: "Phone Number",
    icon: "phone",
    phoneNumber: "9481815122",
  },
];
