import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import RequestDialog from "./request_dialog/request_dialog";

const RequestsList = ({ business_id }) => {
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [newRequests, setNewRequests] = useState([]);
  const [respondedRequests, setRespondedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);


  // Function to reload requests data
  const reloadFunction = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/business-edit-request/`, config)
      .then((response) => {
  
        const filteredNewRequests = response.data
          .filter(({ status }) => status === "Pending")
          .map(({ edits, id, status, created_at, business_tittle }) => ({
            ...edits,
            id,
            status,
            created_at,
            business_tittle
          }));
  
        const filteredRespondedRequests = response.data
          .filter(({ status }) => status !== "Pending")
          .map(({ edits, id, status, created_at, business_tittle }) => ({
            ...edits,
            id,
            status,
            created_at,
            business_tittle
          }));
  
        setNewRequests(filteredNewRequests);
        setRespondedRequests(filteredRespondedRequests);
  
        setLoading(false);
      })
      .catch((error) => {
        console.error("New requests error:", error);
        setLoading(false);
      });
  };
  

  useEffect(() => {
    reloadFunction();
  }, [business_id]);

  const handleAdminResponse = (id, action) => {
    setLoading(true);
  
    const payload = {
      status: action === "accept" ? "Accepted" : "Rejected",
    };
  
    axios
      .post(
        `${baseUrl}superadmin/business-edit-request-update/${id}/`,  
        payload,  
        {
          headers: {
            "Content-Type": "application/json", 
            Authorization: config.headers.Authorization, 
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        reloadFunction();  
      })
      .catch((error) => {
        console.error("Error updating request status:", error);
      })
      .finally(() => {
        setLoading(false);  
      });
  };
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

 

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const editLists = (id) => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/business-edit-request-by-id/${id}/`, config)
      .then((response) => {
        
        setSelectedRequest(response.data); 
        setModalOpen(true); 
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching request details:", error);
        setLoading(false);
      });
  };
  
  const columns = [
    
    {field: "business_tittle", headerName: "Business Name", width: 200},
    { field: "status", headerName: "Status", width: 200 },
    {field: "created_at", headerName: "Created Date", width: 200},
    {
      field: "actions",
      headerName: "View Details",
      width: 200,
      renderCell: (params) => (
        <Button onClick={() => editLists(params.row.id)}  sx={{
          textTransform: "none", 
          color: "orangered",  
          borderColor: "orangered", 
          "&:hover": {
            borderColor: "orangered",
          },
        }} variant="outlined"
     >
          View Details
        </Button>
      ),
    },
    {
      field: "acceptReject",
      headerName: "Decision",
      width: 200,
      renderCell: (params) => (
        <>
          <Popup
            className="popup-container"
            contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
            trigger={<Button className="accept-btn m-1">Accept</Button>}
            modal
            nested
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">
                  Do you really want to Accept?
                </h3>
                <div className="delete-btn-div">
                  <Button
                    onClick={() => {
                      handleAdminResponse(params.row.id, "accept");
                      close();
                    }}
                  >
                    Ok
                  </Button>
                  <Button className="request-cancel" onClick={close}>Cancel</Button>
                </div>
              </div>
            )}
          </Popup>
          <Popup
            className="popup-container"
            contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
            trigger={<Button className="reject-btn m-1">Reject</Button>}
            modal
            nested
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">
                  Do you really want to Reject?
                </h3>
                <div className="delete-btn-div">
                  <Button
                    onClick={() => {
                      handleAdminResponse(params.row.id, "reject");
                      close();
                    }}
                  >
                    Ok
                  </Button>
                  <Button className="request-cancel" onClick={close}>Cancel</Button>
                </div>
              </div>
            )}
          </Popup>
        </>
      ),
    },
  ];

  const respondedColumns = columns.filter(
    (column) => column.field !== "acceptReject"
  );

  const requestColumns = columns.filter(
    (column) => column.field !== "admin_response"
  );

  return (
    <TabContext value={tabValue}>
      <Box className="box-container">
        <TabList aria-label="lab API tabs example" onChange={handleTabChange}>
          <Tab label="New request" sx={{ textTransform: "none" }} value="1" />
          <Tab
            label="Responded request"
            sx={{ textTransform: "none" }}
            value="2"
          />
        </TabList>
        <TabPanel value="1">
          <DataGrid
            pageSize={20}
            autoHeight={true}
            getRowId={(row) => row.id}
            rows={newRequests}
            columns={requestColumns}
            editMode="row"
            loading={loading}
          />
        </TabPanel>
        <TabPanel value="2">
          <DataGrid
            pageSize={20}
            autoHeight={true}
            getRowId={(row) => row.id}
            rows={respondedRequests}
            columns={respondedColumns}
            editMode="row"
            loading={loading}
          />
        </TabPanel>
      </Box>
      <RequestDialog modalOpen={modalOpen} handleCloseModal={handleCloseModal} selectedRequest={selectedRequest}/>

    </TabContext>
  );
};

export default RequestsList;









