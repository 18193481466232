
import "./gallery.css";
import React, { useState, useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { baseUrl } from "../../../../network/constants/constants";
import axios from "../../../../network/api";
import config from "../../../../network/auth";
import { Spinner } from "react-bootstrap";

function Gallery({ business_id }) {
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState(null); 

  // delete image
  const deleteImage = (imageId) => {
    setLoading(true);

    axios
      .delete(baseUrl + `app/gallery/delete/${imageId}/`, config)
      .then((response) => {
        setImageList(imageList.filter((image) => image.id !== imageId));
        setLoading(false);
      })
      .catch((error) => {
        console.error("image Error:", error);
        setLoading(false);
      });
  };

  const fetchGallery = () => {
    setLoading(true);
    axios
      .get(baseUrl + `superadmin/gallery-list-by-id/${business_id}/`, config)
      .then((response) => {
        setLoading(false);
        setImageList(response.data);
      })
      .catch((error) => {
        console.error("Gallery Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  // post banner
  const handleGallerySubmit = (event, close) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("gallery_images", selectedFile);
    formData.append("b_id", business_id);

    axios
      .post(`${baseUrl}superadmin/gallery/create/`, formData, config)
      .then((response) => {
        fetchGallery();
        setImageList([...imageList, response.data]);
        setSelectedFile("");
        setFileName("");
        setPreview(null); 
        close();
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  };

  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);

    
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result); 
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  return (
    <div className="main-div">
      {loading ? (
        <div className="loader-container">
          <Spinner className="spinner-loader" />
        </div>
      ) : (
        <>
          {imageList.length > 0 ? (
            <div style={{ padding: "10px" }}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter="20px">
                  {imageList.map((image, i) => (
                    <div className="gallery-img-div" key={image.id}>
                      <Popup
                        className="popup-container"
                        contentStyle={{
                          borderRadius: "20px",
                          backgroundColor: "white",
                        }}
                        trigger={
                          <span
                            className="material-icons delete-icon"
                            onClick={() => deleteImage(image.id)}
                          >
                            delete_forever
                          </span>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="delete-img-div">
                            <h3 className="delete-img-heading">
                              Are you sure?
                            </h3>
                            <p className="delete-img-p">
                              Do you really want to delete?
                            </p>
                            <div className="delete-btn-div">
                              <button
                                onClick={() => {
                                  deleteImage(image.id);
                                  close();
                                }}
                              >
                                Delete
                              </button>
                              <button onClick={close}>Cancel</button>
                            </div>
                          </div>
                        )}
                      </Popup>
                      <img
                        src={`${baseUrl}${image.gallery_images}`}
                        style={{
                          width: "100%",
                          display: "block",
                          cursor: "pointer",
                        }}
                        alt="images"
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>No images available</p>
          )}

          <Popup
            className="add-popup-container"
            contentStyle={{ borderRadius: "20px" }}
            trigger={<button className="btn-add">+ Add Image</button>}
            modal
            nested
          >
            {(close) => (
              <div className="add-img-div">
                <form
                  method="post"
                  onSubmit={(e) => {
                    handleGallerySubmit(e, close);
                  }}
                >
                  <h3 className="add-img-heading">Add gallery image</h3>
                  <label className="add-file-upload">
                    <input
                      className="add-img-file"
                      id="file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <MdOutlineFileUpload className="upload-icon" />
                  </label>
                  {fileName && (
                    <p className="selected-file-name m-2">
                      Selected file: {fileName}
                    </p>
                  )}
                  {preview && (
                    <img
                      src={preview}
                      alt="Selected Preview"
                      style={{
                        width: "50%",
                        marginTop: "10px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                  <div className="submit-banner-file-container">
                    <button type="submit">Upload</button>
                    <button
                      type="button"
                      onClick={() => {
                        close();
                        setSelectedFile("");
                        setFileName("");
                        setPreview(null); 
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Popup>
        </>
      )}
    </div>
  );
}

export default Gallery;









