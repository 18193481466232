import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import "./feedback-screen.css";
import config from "../../../../network/auth";
import { baseUrl } from "../../../../network/constants/constants";
import axios from "../../../../network/api";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";
import { Spinner } from "react-bootstrap";

export default function FeedbackScreen() {
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);

  // Get Feedback
  const fetchFeedback = () => {
    setLoading(true);

    axios
      .get(`${baseUrl}app/feedback/`, config)
      .then((response) => {
        setLoading(false);
        setFeedback(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("fetchFeedback Error:", error);
      });
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  // Open delete dialog
  const handleOpenDeleteDialog = (id) => {
    setFeedbackId(id);
    setOpenDeleteDialog(true);
  };

  // Close delete dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  // Handle delete confirmation
  const handleConfirmDelete = async (feedbackId) => {
    if (feedbackId !== null) {
      setLoading(true);
      try {
        await axios.delete(
          `${baseUrl}app/feedback/delete/${feedbackId}/`,
          config
        );

        setFeedback((prevFeedback) =>
          prevFeedback.filter((item) => item.id !== feedbackId)
        );
      } catch (error) {
        console.error("Error deleting feedback:", error);
      } finally {
        setLoading(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 180, editable: true },
    {
      field: "name",
      headerName: "User",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "created_at",
      headerName: "Feedback date",
      width: 180,
      editable: true,
    },
    {
      field: "message",
      headerName: "Message",
      width: 220,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            className="feedback-delete"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleOpenDeleteDialog(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Spinner className="spinner-loader" />
        ) : (
          <DataGrid
            className="feedback-list"
            rows={feedback}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            loading={loading}
          />
        )}
      </Box>
      <Popup
        contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        modal
        nested
      >
        {(close) => (
          <div className="delete-div">
            <h3 className="delete-heading">Are you sure?</h3>
            <p className="delete-p">Do you want to delete this feedback?</p>
            <div className="delete-btn-div">
              <Button onClick={() => handleConfirmDelete(feedbackId)}>
                Confirm
              </Button>
              <Button onClick={handleCloseDeleteDialog} className="cancel-btn">
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
}
