import React, { useEffect, useState } from "react";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import { Button, Spinner } from "react-bootstrap";
import './blog-screen.css';
import  BlogForm  from "../blog-component/blog-form";
import  BlogTable  from "../blog-component/blog-component";
import './blog-screen.css'


export const BlogScreen = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  // Get categories
  const fetchBlogs = () => {
    setLoading(true);
    axios
      .get(baseUrl + `web/blog-list/`, config)
      .then((response) => {
        setBlogs(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Blogs Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleShowModal = (blog = null) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBlog(null);
  };

  const handleFormSubmit = (formData) => {
    const url = selectedBlog
      ? `${baseUrl}web/blog-edit-delete/${selectedBlog.id}/`
      : `${baseUrl}web/blog-create/`;
    const method = selectedBlog ? "patch" : "post";

    const form = new FormData();
    form.append("id", formData.id);
    form.append("blog_title", formData.blog_title);
    form.append("blog_body", formData.blog_body);
    form.append("blog_author", formData.blog_author);
    
    if (formData.blog_author_image) {
      form.append("blog_author_image", formData.blog_author_image);
    }
  
    form.append("blog_date", formData.blog_date);
  
    if (formData.blog_image) {
      form.append("blog_image", formData.blog_image);
    }

    axios({
      method,
      url,
      data: form,
      headers: config.headers,
    })
      .then((response) => {
        fetchBlogs();
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error saving blogs:", error);
      });
  };
  const deleteCategory = (categoryId) => {
    setLoading(true);
  
    axios
      .delete(`${baseUrl}web/blog-edit-delete/${categoryId}/`, config)
      .then((response) => {
        fetchBlogs(); 
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        setLoading(false);
      });
  };
  
  return (
    
    <div className="blog-container">
   
      <button className="btn-add"  onClick={() => handleShowModal()}>+ Add Blog</button>
      {loading ? (
          <div className="loader-container">
        <Spinner className='spinner-loader' />
        </div>
      ) : (
      <div className="blog-grid">
           <BlogTable
          blogs={blogs} 
         loading={loading} onEdit={handleShowModal}
           onDelete={deleteCategory} 
          />
      </div>
      )}
      <BlogForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleFormSubmit}
        blog={selectedBlog}
      />
    
    </div>
  );
};

export default BlogScreen;


