import "./login.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap/";
import Logo from "./../../assets/logo.svg";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/constants";


function Login() {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [phoneError, setPhoneError] = useState(false);
  const navigate = useNavigate();

  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const validatePhone = (phone) => {
    const regex = /^(?:\+91\s?)?[6-9]\d{9}$/;
    setPhoneError(!regex.test(phone));
    return regex.test(phone);
  };


  const PostLogin = (props) => {
    setLoading(true);

    const payload = {
      phone: phone,
    };

    console.log("payload: " + JSON.stringify(payload));

    axios
      .post(baseUrl + "auth/auth/login/", payload)
      .then((response) => {
        console.log("<><><><><><>" + JSON.stringify(response.data));
        setLoading(false);
        const dataToSend = { "phone": phone };
        navigate("../otp", { state: { data: dataToSend }, replace: true });
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(true);
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <img alt="logo" src={Logo} className="logo"/>
          <div className="business-name">Onescribe Super Admin Panel</div>
        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">Login</div>

          <TextField
            id="phone"
            className="mt-4"
            label="Phone"
            variant="outlined"
            size="small"
            type="phone"
            value={phone}
            onChange={handlePhone}
            error={phoneError}
            helperText={phoneError ? "Invalid Mobile Number" : ""}
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validatePhone(phone)) {
                setLoading(true);
                // handleNavigation;
                // alert("clicked");
                PostLogin();

              }
            }}
          >
            Submit
          </Button>
<div className="spiner-container">
          <TailSpin
          className="mt-5"
            height="30"
            width="30"
            radius="1"
            color="#ffbe00"
            ariaLabel="loading"
            visible={loading}
            // wrapperStyle
            // wrapperClass
          />
          </div>
        </Card>
      </div>

      <a href="tel:+91" className="contact">ⓘ Contact Us</a>
    </section>
  );
}

export default Login;
