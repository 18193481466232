import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "./plan_form.css";

export const PlanForm = ({ show, handleClose, handleSubmit, plan }) => {
    const [formData, setFormData] = useState({
        plan_title: plan?.plan_title || "",
      });
      
  useEffect(() => {
    if (plan) {
      setFormData({
        plan_title: plan.plan_title,
      });
    }
  }, [plan]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };
  return (
    <Modal className="popup-model" contentClassName="modal-height" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{plan ? "Edit Plan" : "Add Plan"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-category-div">
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="formCategoryName">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              type="text"
              name="plan_title"
              value={formData.plan_title}
              onChange={handleChange}
              className="mb-4"
              required
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className="w-100 update-add-btn"
          >
            {plan ? "Update" : "Add"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};


