import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";

function BlogForm({ show, handleClose, handleSubmit, blog }) {
  const [formData, setFormData] = useState({
    blog_title: blog?.blog_title || "",
    blog_body: blog?.blog_body || "",
    blog_author: blog?.blog_author || "",
    blog_author_image: null,
    blog_date: blog?.blog_date || "",
    blog_image: null,
  });

  useEffect(() => {
    if (blog) {
      setFormData({
        blog_title: blog.blog_title,
        blog_body: blog.blog_body,
        blog_author: blog.blog_author,
        blog_author_image: null,
        blog_date: blog.blog_date,
        blog_image: null,
      });
    }
  }, [blog]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? e.target.files[0] : value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <Modal contentClassName="modal-height" size="md" className="popup-model" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{blog ? "Edit Blog" : "Add Blog"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-category-div">
        <Form onSubmit={handleFormSubmit}>
          
          <Form.Group controlId="formBlogTitle">
            <Form.Label>Blog Title</Form.Label>
            <Form.Control
              type="text"
              name="blog_title"
              value={formData.blog_title}
              onChange={handleChange}
              required
              maxLength={150}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formBlogBody">
            <Form.Label>Blog Body</Form.Label>
            <Form.Control
              as="textarea"
              name="blog_body"
              value={formData.blog_body}
              onChange={handleChange}
              required
              minLength={1}
            />
          </Form.Group>

         
          <Form.Group controlId="formBlogAuthor">
            <Form.Label>Blog Author</Form.Label>
            <Form.Control
              type="text"
              name="blog_author"
              value={formData.blog_author}
              onChange={handleChange}
              required
              maxLength={70}
              minLength={1}
            />
          </Form.Group>

     
          <Form.Group controlId="formBlogAuthorImage">
            <Form.Label>Blog Author Image</Form.Label>
            <Form.Control
              type="file"
              name="blog_author_image"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formBlogDate">
            <Form.Label>Blog Date</Form.Label>
            <Form.Control
              type="date"
              name="blog_date"
              value={formData.blog_date}
              onChange={handleChange}
              required
            />
          </Form.Group>

         
          <Form.Group controlId="formBlogImage">
            <Form.Label>Blog Image</Form.Label>
            <Form.Control
              type="file"
              name="blog_image"
              onChange={handleChange}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="w-100 update-add-btn"
          >
            {blog ? "Update" : "Add"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BlogForm;
