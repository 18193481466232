import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Popup from "reactjs-popup";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import "./unsubscribe.css";

const Unsubscribe = ({business_id}) => {
  const [newRequests, setNewRequests] = useState([]);
  const [respondedRequests, setRespondedRequests] = useState([]);
  const [tableValue, setTableValue] = useState("1");
  const [rejectReason, setRejectReason] = useState("");
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loading, setLoading] = useState(false);

  const reloadFunction = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/cancelled-sub-details/${business_id}/`, config)
      .then((response) => {
        // console.log(response.data)
        const filteredRequest = response.data.filter(
          ({ cancellation_status }) => cancellation_status === "PENDING"
        );
        const filteredResponse = response.data.filter(
          ({ cancellation_status }) => cancellation_status !== "PENDING"
        );
        setRespondedRequests(filteredResponse);
        setNewRequests(filteredRequest);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Cancellation request error:", error);
        setLoading(false);
      });
  };


  useEffect(() => {
    reloadFunction();
  }, []);

   // post adminresponse
   const handleAdminResponse = (subscription_id, action) => {
    setLoading(true);

    const payload = {
      subscription_id: subscription_id,
      admin_response: action === "reject" ? rejectReason : "",
      status: action === "accept" ? "CANCELLED" : "REJECTED",
    };
    
    axios
      .post(baseUrl+"app/cancel-by-admin/", payload, config)
      .then((response) => {
        reloadFunction();
        setLoading(false);
        setRejectReason("");
        console.log(response.data)
      })
      .catch((error) => {
        console.error("Admin response error:", error);
        setLoading(false);
      });
  };

  const columns = [
    { field: "subscription_id", headerName: "ID", width: 70 },
    {
      field: "subscription_product",
      headerName: "Subscription name",
      width: 130,
    },
    {
      field: "subscription_amount",
      headerName: "Price",
      type: "number",
      width: 80,
    },
    {
      field: "visits_left",
      headerName: "Visit left",
      type: "number",
      width: 90,
    },
    {
      field: "days_left",
      headerName: "Days left",
      sortable: false,
      width: 100,
    },
    {
      field: "expiry_date",
      type: "number",
      headerName: "Expiry date",
      width: 100,
    },
    { field: "customer_name", headerName: "Customer name", width: 130 },
    { field: "order_paid_date", headerName: "Date & Time", width: 100 },
    { field: "reason", headerName: "Reason", width: 130 },
    { field: "cancellation_status", headerName: "Status", width: 90 },
    { field: "admin_response", headerName: "Admin response", width: 200 },
    {
      field: "acceptReject",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (params) => [
        <>
          <Popup
            className="popup-container"
            contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
            trigger={<Button className="accept-btn">Accept</Button>}
            modal
            nested
            onOpen={() => setSubscriptionId(params.row.subscription_id)}
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">
                  Do you really want to Accept ?
                </h3>
                <div className="delete-btn-div">
                  <Button
                    onClick={() => {
                      handleAdminResponse(params.row.subscription_id, "accept");
                      close();
                    }}
                  >
                    Ok
                  </Button>
                  <Button onClick={close}>Cancel</Button>
                </div>
              </div>
            )}
          </Popup>
          <Popup
            className="popup-container"
            contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
            trigger={<Button className="reject-btn">Reject</Button>}
            modal
            nested
            onOpen={() => setSubscriptionId(params.row.subscription_id)}
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">Rejection Reason</h3>
                <TextField
                  className="contact-form-textfield"
                  label="Type reason"
                  multiline
                  rows={4}
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                />
                <div className="delete-btn-div">
                  <Button
                    onClick={() => {
                      handleAdminResponse(params.row.subscription_id, "reject");
                      close();
                    }}
                  >
                    Ok
                  </Button>
                  <Button onClick={close}>Cancel</Button>
                </div>
              </div>
            )}
          </Popup>
        </>,
      ],
    },
  ];

  const respondedColumns = columns.filter(
    (column) => column.field !== "acceptReject"
  );

  const requestColumns = columns.filter(
    (column) => column.field !== "admin_response"
  );

  const handleChange = (event, newValue) => {
    setTableValue(newValue);
  };

  return (
    <TabContext value={tableValue}>
      <Box className="box-container">
        <TabList onChange={handleChange} aria-label="lab API tabs example"  TabIndicatorProps={{
            style: {
              backgroundColor: "orangered",
            },
          }}  >
          <Tab label="New request" sx={{ textTransform: "capitalize" }} value="1" className="submenu-items" />
          <Tab
            label="Responded request"
            sx={{ textTransform: "capitalize" }}
            value="2"
            className="submenu-items"
          />
        </TabList>
        <TabPanel value="1">
          <DataGrid
            pageSize={20}
            autoHeight={true}
            getRowId={(row) => row.subscription_id}
            rows={newRequests}
            columns={requestColumns}
            editMode="row"
            loading={loading}
          />
        </TabPanel>
        <TabPanel value="2">
          <DataGrid
            pageSize={20}
            autoHeight={true}
            getRowId={(row) => row.subscription_id}
            rows={respondedRequests}
            columns={respondedColumns}
            editMode="row"
            loading={loading}
          />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default Unsubscribe;
