import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { MdEdit, MdDelete } from "react-icons/md";
import PageviewIcon from "@mui/icons-material/Pageview";
import Popup from "reactjs-popup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./plan_table.css";
export const PlanTable = ({ plan, onEdit, onDelete, onPageChange }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const handleDeleteClick = (pln) => {
    setSelectedPlan(pln);
  };

  const confirmDelete = () => {
    if (setSelectedPlan) {
      onDelete(selectedPlan.id);
      setSelectedPlan(null);
    }
  };
  const handlePlanClick = (planId) => {
    onPageChange("subplans", planId);
  };
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Plan Name</th>
            <th>Actions</th>
            <th>Add and View Subplan</th>
          </tr>
        </thead>
        <tbody>
          {plan.length === 0 ? (
            <tr>
              <td colSpan={4} className="text-center">
                No plans available
              </td>
            </tr>
          ) : (
            plan.map((pln) => (
              <tr key={pln.id}>
                <td>{pln.id}</td>
                <td>{pln.plan_title}</td>
                <td>
                  <div className="edit-delete-category">
                    <div className="edit">
                      <MdEdit
                        variant="primary"
                        className="ml-2"
                        onClick={() => onEdit(pln)}
                      />
                    </div>
                    <div className="delete">
                      <MdDelete onClick={() => handleDeleteClick(pln)} />
                    </div>
                  </div>
                </td>
                <td>
                  <VisibilityIcon
                    className="view-subplan"
                    onClick={() => handlePlanClick(pln.id)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {selectedPlan && (
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={true}
          onClose={() => setSelectedPlan(null)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button
                  onClick={() => {
                    confirmDelete();
                    close();
                  }}
                >
                  Delete
                </button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};
