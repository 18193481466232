
import React, { useState } from "react";
import { PlanScreen } from "./plan_screen_main/plan_screen_main";
import { SubplanScreen } from "./subplan_screen_main/subplan_screen_main";


const MainScreen = ({business_id}) => {
  const [currentPage, setCurrentPage] = useState("plans");
  const [selectedPlanId, setSelectedPlanId] = useState(null);


  const handlePageChange = (page, planId = null) => {
    setCurrentPage(page);
    setSelectedPlanId(planId);
  
  };

  return (
    <div>
      {currentPage === "plans" && <PlanScreen business_id={business_id} onPageChange={handlePageChange} />}
      {currentPage === "subplans" && (
        <SubplanScreen onPageChange={handlePageChange} business_id={business_id} planId={selectedPlanId} />
      )}
    </div>
  );
};

export default MainScreen;
