import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./subplan_form.css";

export const SubplanForm = ({ show, handleClose, handleSubmit, plan }) => {
  const [formData, setFormData] = useState({
    sub_plan_title: "",
    price: "",
    validity: "",
    visits: "",
    plan_info: [{ info: "" }],
  });

  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    if (plan) {
      setFormData({
        sub_plan_title: plan.sub_plan_title || "",
        price: plan.price || "",
        validity: plan.validity || "",
        visits: plan.visits || "",
        plan_info: plan.plan_info
          ? plan.plan_info.map((info) => ({ info }))
          : [{ info: "" }],
      });
    }
  }, [plan]);

  const handleInfoChange = (index, event) => {
    const values = [...formData.plan_info];
    values[index].info = event.target.value;
    setFormData({ ...formData, plan_info: values });
  };

  const handleAddPlanInfoChange = () => {
    setFormData({
      ...formData,
      plan_info: [...formData.plan_info, { info: "" }],
    });
  };

  const handleRemovePlanInfo = (index) => {
    const values = [...formData.plan_info];
    values.splice(index, 1);
    setFormData({ ...formData, plan_info: values });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      plan_info: formData.plan_info.map((infoObj) => infoObj.info),
    };
    handleSubmit(dataToSubmit);
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      sub_plan_title: "",
      price: "",
      validity: "",
      visits: "",
      plan_info: [{ info: "" }],
    });
  };
  return (
    <Modal
      className="popup-model"
      contentClassName="modal-height"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{plan ? "Edit Subplan" : "Add Subplan"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-category-div">
        <Form onSubmit={onSubmit}>
          <Container>
            <Form.Group controlId="formSubplanTitle">
              <Form.Label>Subplan Title</Form.Label>
              <Form.Control
                type="text"
                name="sub_plan_title"
                value={formData.sub_plan_title}
                onChange={(e) =>
                  setFormData({ ...formData, sub_plan_title: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
                placeholder="Enter price (optional)"
              />
            </Form.Group>

            <Form.Group controlId="formValidity">
              <Form.Label>Validity (in days)</Form.Label>
              <Form.Control
                type="number"
                name="validity"
                value={formData.validity}
                onChange={(e) =>
                  setFormData({ ...formData, validity: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group controlId="formVisits">
              <Form.Label>Visits</Form.Label>
              <Form.Control
                type="number"
                name="visits"
                value={formData.visits}
                onChange={(e) =>
                  setFormData({ ...formData, visits: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group controlId="formPlanInfo">
              <Form.Label>Plan Info</Form.Label>
              {formData.plan_info.map((infoObj, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <Form.Control
                    value={infoObj.info}
                    onChange={(e) => handleInfoChange(index, e)}
                    placeholder={`Enter plan info ${index + 1}`}
                  />
                  <span
                    onClick={() => handleRemovePlanInfo(index)}
                    className="material-icons remove-icon"
                  >
                    delete_forever
                  </span>
                </div>
              ))}
              <div className="add-info">
              <Button className="mb-2 add-planinfo-btn "  onClick={handleAddPlanInfoChange}>
                + Plan Info
              </Button>
              </div>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="w-100 update-add-btn"
            >
              {plan ? "Update" : "Add"}
            </Button>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
