import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { PlanTable } from "./plan_screen_components/plan_table/plan_table";
import { PlanForm } from "./plan_screen_components/plan_form/plan_form";
import './plan_screen_main.css';
import { Spinner } from "react-bootstrap";


export const PlanScreen = ({business_id, onPageChange}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState([]);


 

  const handleShowModal = (plan = null) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
 

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPlan(null);
  };
// Get plans
const fetchPlan = () => {
    setLoading(true);
    axios
      .get(baseUrl + `superadmin/plan-by-bizid/${business_id}/`, config)
      .then((response) => {
        setPlan(response.data);
        setLoading(false);

      })
      .catch((error) => {
        console.error("Plan Error:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchPlan();
  }, [business_id]);
  const handleFormSubmit = (formData) => {
    const url = selectedPlan
      ? `${baseUrl}superadmin/plan/update-delete/${selectedPlan.id}/`
      : `${baseUrl}superadmin/plan/list-create/`;
    const method = selectedPlan ? "patch" : "post";

    const form = new FormData();
    form.append("plan_title", formData.plan_title);
    form.append("biz_id", business_id);

    axios({
      method,
      url,
      data: form,
      headers: config.headers,
    })
      .then((response) => {
        fetchPlan();
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error saving plan:", error);
      });
  };

  const deletePlan = (planId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}superadmin/plan/update-delete/${planId}/`, config)
      .then((response) => {
        fetchPlan();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting plan:", error);
        setLoading(false);
      });
  };

  return (
    <div className="plan-container">
      <div className="plan-btn-container">
        <button className="add-plan p-2 m-2" onClick={() => handleShowModal()}>
          + Add Plan Title
        </button>
      </div>
      {loading ? (
          <div className="loader-container">
        <Spinner className='spinner-loader' />
        </div>
      ) : (
      <div>
        <PlanTable
          plan={plan}
          loading={loading}
          onEdit={handleShowModal}
          onDelete={deletePlan}
          onPageChange={onPageChange}
        />
      </div>
        )}
      <PlanForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleFormSubmit}
        plan={selectedPlan}
      />
    </div>
  );
};
