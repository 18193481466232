import React, { useState, useEffect } from "react";
import "./top_header.css";
import { Card, Container } from "react-bootstrap";
import TabMenu from "../../tab_menu/tab_menu";
import { Button } from "@mui/material";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import { Spinner } from "react-bootstrap";

const TopHeader = ({ onPageChange, business_id }) => {
  const [headerDetails, setHeaderDetails] = useState([]);
  const [SubscriptionBz, setSubscriptionBz] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBzLogoName = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/business-details/${business_id}/`, config)
      .then((response) => {
        setLoading(false);
        setHeaderDetails(response.data);
        
      })
      .catch((error) => {
        console.error("Business Error:", error);
        setLoading(false);
      });
  };

  const fetchBusinessHeader = () => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}superadmin/subscription-by-business-id/${business_id}/`,
        config
      )
      .then((response) => {
        setLoading(false);
        setSubscriptionBz(response.data);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data.message) {
          setError(error.response.data.message); 
        } 
      });
  };

  useEffect(() => {
    if (business_id) {
      fetchBusinessHeader();
      fetchBzLogoName();
    }
  }, [business_id]);

  const handleBusinessClick = () => {
    onPageChange("business");
  };

  if (loading) {
    return <div className="loader-container"> <Spinner className='spinner-loader' /></div>;
  }

  return (
    <div>
      <Container fluid className="parent-top">
        <Button className="go-back" onClick={handleBusinessClick}>
          Go Back
        </Button>
        {headerDetails ? (
          <Card className="top-menu-container" key={headerDetails.business_id}>
            <div className="top-menu-business-head pb-3 pt-3">
              <div>
                <img
                  className="business-logo"
                  src={`${baseUrl}${headerDetails.logo}`}
                  alt={headerDetails.business_title}
                />
              </div>
              <div className="business-head">
                {headerDetails.business_title}
              </div>

               {error ? ( 
                <div className="error-message">
                  <p>{error}</p>
                </div>
              ) : SubscriptionBz ? (
                <div className="subscription-container">
                  <div className="subcription-details-container">
                    <div className="subscription-details">
                      Total Subscriptions
                      <span className="sub-number">{SubscriptionBz.total_subscriptions}</span>
                    </div>
                    <div className="subscription-details">
                      Cancelled Subscriptions
                      <span className="sub-number">
                        {SubscriptionBz.cancelled_subscriptions}/
                        {SubscriptionBz.total_subscriptions}
                      </span>
                    </div>
                    <div className="subscription-details">
                      Inactive Subscriptions
                      <span className="sub-number">
                        {SubscriptionBz.inactive_subscriptions}/
                        {SubscriptionBz.total_subscriptions}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="clean">
                    No subscription details available currently.
                  </p>
                </div>
              )}
            </div>
            <TabMenu business_id={business_id} />
          </Card>
        ) : (
          <div>
            <p className="clean">No Business Details</p>
          </div>
        )}
      </Container>
    </div>
  );
};

export default TopHeader;
