import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { MdEdit, MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";

export const SubplanTable = ({ subplans, onEdit, onDelete }) => {
  const [selectedSubplan, setSelectedSubplan] = useState(null);
  const handleDeleteClick = (subpln) => {
    setSelectedSubplan(subpln);
  };

  const confirmDelete = () => {
    if (selectedSubplan) {
      onDelete(selectedSubplan.id);
      setSelectedSubplan(null);
    }
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Subplan Name</th>
            <th>Price</th>
            <th>Validity</th>
            <th>Visits</th>
            <th>Plan Info</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {subplans.length === 0 ? (
            <tr>
              <td colSpan={12} className="text-center">
                No subplans available
              </td>
            </tr>
          ) : (
            subplans.map((subpln) => (
              <tr key={subpln.id}>
                <td>{subpln.id}</td>
                <td>{subpln.sub_plan_title}</td>
                <td>{subpln.price}</td>
                <td>{subpln.validity}</td>
                <td>{subpln.visits}</td>
                <td>
                  <ul>
                    {subpln.plan_info.map((info, index) => (
                      <li key={index}>{info}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <div className="edit-delete-category">
                    <div className="edit">
                      <MdEdit
                        variant="primary"
                        className="ml-2"
                        onClick={() => onEdit(subpln)}
                      />
                    </div>
                    <div className="delete">
                      <MdDelete onClick={() => handleDeleteClick(subpln)} />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {selectedSubplan && (
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={true}
          onClose={() => setSelectedSubplan(null)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button
                  onClick={() => {
                    confirmDelete();
                    close();
                  }}
                >
                  Delete
                </button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};
