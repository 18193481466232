import axios from "axios";
import { baseUrl } from "./constants/constants";
//  import {config} from "./constants/Constants"

const instance=axios.create({

baseUrl:baseUrl,
// config:config  
})

export default instance;