import React, { useState } from 'react';
import BusinessComponent from './business/business';
import TopHeader from './top_header/top_header';

const MainBusiness = () => {
  const [currentPage, setCurrentPage] = useState("business");
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);

  const handlePageChange = (page, business_Id = null) => {
    setCurrentPage(page);
    setSelectedBusinessId(business_Id);
  };

  return (
    <div>
      {currentPage === "business" && <BusinessComponent onPageChange={handlePageChange} />}
      {currentPage === "sub_business" && (
        <TopHeader onPageChange={handlePageChange} business_id={selectedBusinessId} />
      )}
    </div>
  );
};

export default MainBusiness;
