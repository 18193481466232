import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";
import "./business_form.css";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const BusinessForm = ({
  show,
  handleClose,
  handleSubmit,
  business,
  categoryList,
}) => {
  const [formData, setFormData] = useState({
    business_title: business?.business_title || "",
    mobile_number: business?.mobile_number || "",
    category_id: business?.category_id || "",
    description: business?.description || "",
    service_range_km: business?.service_range_km || "",
    service_localities: business?.service_localities || "",
    business_place: business?.business_place || "",
    latitude: business?.latitude || "",
    longitude: business?.longitude || "",
    logo: null,
    thumb: null,
    banner: null,
    phone: business?.phone,
    name: business?.name,
    // password: business?.password,
    user_place: business?.user_place,
  });

  const [phone, setPhone] = useState(business?.phone);
  const [name, setName] = useState(business?.name);
  const [userPlace, setUserPlace] = useState(business?.user_place);
  // const [password, setPassword] = useState(business?.password);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState({
    phone: "",
    name: "",
    user_place: "",
    // password: "",
    business_title: "",
    mobile_number: "",
  });

  const [selectedUser, setSelectedUser] = useState(
    business
      ? {
          phone: business.phone || "",
          name: business.name || "",
          user_place: business.user_place || "",
          // password: business.password || "",
        }
      : null
  );

  const [isAutocompleteFailed, setIsAutocompleteFailed] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  useEffect(() => {
    if (business) {
      setFormData({
        business_title: business.business_title,
        mobile_number: business.mobile_number,
        category_id: business.category_id,
        description: business.description,
        service_range_km: business.service_range_km,
        service_localities: business?.service_localities,
        business_place: business.place,
        latitude: business.latitude,
        longitude: business.longitude,
        logo: null,
        thumb: null,
        banner: null,
        phone: business.phone,
        name: business.name,
        // password: business.password,
        user_place: business.user_place,
      });
      setSelectedUser({
        phone: business.phone,
        name: business.name,
        user_place: business.user_place,
        password: business.password,
      });
      setPhone(business.phone);
      setName(business.name);
      setUserPlace(business.user_place);
      // setPassword(business.password);
    } else {
      setFormData({
        business_title: "",
        mobile_number: "",
        category_id: "",
        description: "",
        service_range_km: "",
        service_localities: "",
        business_place: "",
        latitude: "",
        longitude: "",
        logo: null,
        thumb: null,
        banner: null,
        phone: "",
        name: "",
        // password: "",
        user_place: "",
      });
      setSelectedUser(null);
      setPhone("");
      setName("");
      setUserPlace("");
      // setPassword("");
    }
  }, [business]);

  useEffect(() => {
    if (inputValue) {
      axios
        .post(
          `${baseUrl}app/filter-users/`,
          { phone_start: inputValue },
          config
        )
        .then((response) => {
          const users = response.data.users.map((user) => ({
            phone: user.phone,
            name: user.name,
            // password: user.password,
            userPlace: user.place,
          }));
          setPhoneOptions(users);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      setPhoneOptions([]);
    }
  }, [inputValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setPhone(value);
    setPhoneDirty(true);
    console.log(`Manually entered Phone Number: ${value}`);
    if (errors.phone) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (phoneDirty && (!phone || !/^\d{10}$/.test(phone))) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Please enter a valid 10-digit phone number.',
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }

    const completeFormData = {
      ...formData,
      phone,
      name,
      user_place: userPlace,
      // password,
    };
    setFormData(completeFormData);
    handleSubmit(completeFormData);
    console.log(completeFormData);
  };

  return (
    <Modal
      size="lg"
      fullscreen="sm-down"
      className="business-model"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {business ? "Edit Business and User" : "Add Business and User"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-category-div">
        <Form onSubmit={handleFormSubmit}>
          {!business && (
            <>
              <Modal.Header className="user-heading">User Details</Modal.Header>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    {isAutocompleteFailed ? (
                      <Form.Control
                        type="tel"
                        label="Phone Number"
                        size="small"
                        value={phone}
                        onChange={handlePhoneChange}
                        placeholder="Enter phone number"
                      />
                    ) : (
                      <Autocomplete
                        freeSolo
                        disablePortal
                        id="phone-autocomplete"
                        options={phoneOptions}
                        getOptionLabel={(option) =>
                          `${option.phone} - ${option.name}`
                        }
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          console.log(`Input Value: ${newInputValue}`);
                        }}
                        onChange={(event, newValue) => {
                          const newPhone = newValue
                            ? newValue.phone
                            : inputValue;
                          setPhone(newPhone);
                          setSelectedUser(newValue);
                          console.log(`Selected Phone: ${newPhone}`);

                          if (newValue) {
                            setSelectedUser(newValue);
                            setName(newValue.name);
                            setUserPlace(newValue.user_place);
                            // setPassword(newValue.password);
                            setIsAutocompleteFailed(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="tel"
                            label="Phone Number"
                            size="small"
                            onChange={handlePhoneChange}
                            placeholder="Select or enter phone number"
                          />
                        )}
                      />
                    )}
                    {errors.phone && (
                      <Form.Text className="text-danger">
                        {errors.phone}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="formUserName">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group controlId="formPlace">
                    <Form.Label>User Place</Form.Label>
                    <Form.Control
                      type="text"
                      value={userPlace}
                      onChange={(e) => setUserPlace(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  {/* <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group> */}
                </Col>
              </Row>
            </>
          )}
          <Modal.Header className="business-heading">
            Business Details
          </Modal.Header>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formCategoryName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  name="business_title"
                  value={formData.business_title}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formMobileNumber">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="tel"
                  minlength="10"
                  maxlength="10"
                  pattern="^\d{10}$"
                  name="mobile_number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                  placeholder="Enter 10-digit mobile number"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formCategorySelect">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Category</option>
                  {categoryList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formPlaceBusiness">
                <Form.Label>Business Place</Form.Label>
                <Form.Control
                  type="text"
                  name="business_place"
                  value={formData.business_place}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formLatitude">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formLongitude">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group controlId="formServiceRangeKm">
                <Form.Label>Service Range (KM)</Form.Label>
                <Form.Control
                  type="number"
                  name="service_range_km"
                  value={formData.service_range_km}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group controlId="formServiceLocalities">
                <Form.Label>Service Localities</Form.Label>
                <Form.Control
                  type="text"
                  name="service_localities"
                  value={formData.service_localities}
                  onChange={handleChange}
                  placeholder="Place,Sub place"
                
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Form.Group controlId="formLogo">
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="formThumb">
                <Form.Label>Thumb</Form.Label>
                <Form.Control
                  type="file"
                  name="thumb"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="formBanner">
                <Form.Label>Banner</Form.Label>
                <Form.Control
                  type="file"
                  name="banner"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {business ? "Update Business" : "Add Business"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BusinessForm;
