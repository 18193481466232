import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { menuList } from "./menu_data";
import List from "@mui/material/List";
import { Box } from "@mui/material";

function Menu({ open, sendSelectedItemToParent }) {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index); // Update local state
    sendSelectedItemToParent(index);
  };

  const phoneMenuItem = menuList.findIndex(
    (item) => item.name === "Phone Number"
  );
  const otherMenuItems = menuList.filter((_, index) => index !== phoneMenuItem);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <List sx={{ flexGrow: 1 }}>
        {otherMenuItems.map((obj, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              selected={selectedItem === index}
              onClick={() => {
                handleItemClick(index);
              }}
              aria-label={`Menu item: ${obj.name}`} 
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: "white",
                "&.Mui-selected": {
                  backgroundColor: "#e3e3e3",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <span className="material-icons">{obj.icon}</span>
              </ListItemIcon>
              <ListItemText primary={obj.name} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          component="a"
          href={`tel:${menuList[phoneMenuItem].phoneNumber}`}
          selected={selectedItem === phoneMenuItem}
          onClick={() => {
            handleItemClick(phoneMenuItem);
          }}
          aria-label={`Menu item: Phone Number`}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            backgroundColor: "white",
            color: "#FF6939",
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "#FF6939",
            },
            "&:hover": {
              backgroundColor: "white",
              color: "#FF6939",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: "#FF6939",
            }}
          >
            <span className="material-icons">
              {menuList[phoneMenuItem].icon}
            </span>
          </ListItemIcon>
          <ListItemText
            primary={menuList[phoneMenuItem].name}
            secondary={menuList[phoneMenuItem].phoneNumber}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Box>
  );
}

export default Menu;