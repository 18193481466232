import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { SubplanTable } from "./subplan_table/subplan_table";
import { SubplanForm } from "./subplan_form/subplan_form";
export const SubplanScreen = ({ onPageChange, planId, business_id }) => {
  const [subPlans, setSubPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSubplan, setSelectedSubplan] = useState(null);

  const handleShowModal = (plan = null) => {
    setSelectedSubplan(plan);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSubplan(null);
  };
  const handlePlanClick = () => {
    onPageChange("plans");
  };
  // get subplan
  const fetchSubPlans = (planId) => {
    setLoading(true);
    axios
      .get(`${baseUrl}superadmin/subplan/${planId}/`, config)
      .then((response) => {
        setSubPlans(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (planId) {
      fetchSubPlans(planId);
    }
  }, [planId]);

  const handleFormSubmit = (formData) => {
    const url = selectedSubplan
      ? `${baseUrl}superadmin/subplan/edit/${selectedSubplan.id}/`
      : `${baseUrl}superadmin/subplan/create/`;
    const method = selectedSubplan ? "patch" : "post";

    const form = new FormData();
    form.append("sub_plan_title", formData.sub_plan_title);
    form.append("price", formData.price || ""); 
    form.append("validity", formData.validity);
    form.append("visits", formData.visits);
    form.append(
      "plan_info",
      formData.plan_info ? JSON.stringify(formData.plan_info) : ""
    ); 
    form.append("b_id", business_id);
    form.append("plan_id", planId);
    axios({
      method,
      url,
      data: form,
      headers: config.headers,
    })
      .then((response) => {
        fetchSubPlans(planId); 
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error saving subplan:", error);
      });
  };
  const deleteSubplan = (subplanId) => {
    setLoading(true);
    console.log(`Attempting to delete subplan with ID: ${subplanId}`); 
    axios
      .delete(`${baseUrl}superadmin/subplan/delete/${subplanId}/`, config)
      .then((response) => {
        console.log("Delete response:", response); 
        fetchSubPlans(planId);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting subplan:", error.response || error); 
        setLoading(false);
      });
  };
  
  return (
    <div>
      <Button className="go-back" onClick={handlePlanClick}>
        Go Back
      </Button>
      <div className="plan-btn-container">
        <button className="add-plan m-2 p-2" onClick={() => handleShowModal()}>
          + Add subplan
        </button>
      </div>
      <SubplanTable
        subplans={subPlans}
        loading={loading}
        onEdit={handleShowModal}
        onDelete={deleteSubplan}
      />
      <SubplanForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleFormSubmit}
        plan={selectedSubplan}
      />
    </div>
  );
};





// import React, { useState, useEffect } from "react";
// import { Button } from "@mui/material";
// import { baseUrl } from "../../../../../network/constants/constants";
// import config from "../../../../../network/auth";
// import axios from "../../../../../network/api";
// import { SubplanTable } from "./subplan_table/subplan_table";
// import { SubplanForm } from "./subplan_form/subplan_form";
// export const SubplanScreen = ({ onPageChange, planId, business_id }) => {
//   const [subPlans, setSubPlans] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedSubplan, setSelectedSubplan] = useState(null);

//   const handleShowModal = (plan = null) => {
//     setSelectedSubplan(plan);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedSubplan(null);
//   };
//   const handlePlanClick = () => {
//     onPageChange("plans");
//   };
//   // get subplan
//   const fetchSubPlans = (planId) => {
//     setLoading(true);
//     axios
//       .get(`${baseUrl}superadmin/subplan/${planId}/`, config)
//       .then((response) => {
//         setSubPlans(response.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Subplans Error:", error);
//         setLoading(false);
//       });
//   };
//   useEffect(() => {
//     if (planId) {
//       fetchSubPlans(planId);
//     }
//   }, [planId]);

//   const handleFormSubmit = (formData) => {
//     const url = selectedSubplan
//       ? `${baseUrl}superadmin/subplan/edit/${selectedSubplan.id}/`
//       : `${baseUrl}superadmin/subplan/create/`;
//     const method = selectedSubplan ? "patch" : "post";

//     const form = new FormData();
//     form.append("sub_plan_title", formData.sub_plan_title);
//     form.append("price", formData.price || ""); 
//     form.append("validity", formData.validity);
//     form.append("visits", formData.visits);
//     form.append(
//       "plan_info",
//       formData.plan_info ? JSON.stringify(formData.plan_info) : ""
//     ); 
//     form.append("b_id", business_id);
//     form.append("plan_id", planId);
//     axios({
//       method,
//       url,
//       data: form,
//       headers: config.headers,
//     })
//       .then((response) => {
//         fetchSubPlans(planId); 
//         handleCloseModal();
//       })
//       .catch((error) => {
//         console.error("Error saving subplan:", error);
//       });
//   };
//   const deleteSubplan = (subplanId) => {
//     setLoading(true);
//     console.log(`Attempting to delete subplan with ID: ${subplanId}`); 
//     axios
//       .delete(`${baseUrl}superadmin/subplan/delete/${subplanId}/`, config)
//       .then((response) => {
//         console.log("Delete response:", response); 
//         fetchSubPlans(planId);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error deleting subplan:", error.response || error); 
//         setLoading(false);
//       });
//   };
  
//   return (
//     <div>
//       <Button className="go-back" onClick={handlePlanClick}>
//         Go Back
//       </Button>
//       <div className="plan-btn-container">
//         <button className="add-plan m-2 p-2" onClick={() => handleShowModal()}>
//           + Add subplan
//         </button>
//       </div>
//       <SubplanTable
//         subplans={subPlans}
//         loading={loading}
//         onEdit={handleShowModal}
//         onDelete={deleteSubplan}
//       />
//       <SubplanForm
//         show={showModal}
//         handleClose={handleCloseModal}
//         handleSubmit={handleFormSubmit}
//         plan={selectedSubplan}
//       />
//     </div>
//   );
// };
